import cn from 'classnames';
import styles from './Inputs.module.scss';
import { useId } from 'react';

type InputCheckbox = {
  label: string;  
  name: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (value: boolean) => void;
};

export const InputCheckbox = ({
  label,
  disabled,
  name,
  checked = false,
  onChange
}: InputCheckbox) => (
  <div className="form-checkbox">
    <input
      type="checkbox"
      name={name}
      disabled={disabled}
      className={cn(disabled && styles.disabled)}
      checked={checked}
      onChange={ev => onChange?.(ev.target.checked)}
    />
    <label htmlFor={name}>
      <span>{label}</span>
    </label>
  </div>
);

export const InputRadio = ({
    label,
    disabled,
    name,
    checked = false,
    onChange
}: InputCheckbox) => {
    const id = useId().replace(/:/g, '');
    return <div className="form-radio">
        <input
            type="radio"
            name={name}
            id={id}
            disabled={disabled}
            className={cn(disabled && styles.disabled)}
            checked={checked}
            onChange={ev => onChange?.(ev.target.checked)}
        />
        <label htmlFor={id}>
            <span>{label}</span>
        </label>
    </div>
};